import React from "react"

import Layout from "../components/0_utilities/layout"
import SEO from "../components/0_utilities/seo"

import ContactImg from "../components/1_atoms/banner/img/img-contact"
import Mpt from "../components/1_atoms/images/mpt"
import Pls from "../components/1_atoms/images/pls"

const IndexPage = () => (
  <Layout image={<ContactImg />} pageTitle="Contact">
    <SEO title="Contact" description="Schedule an appointment today!" />
    <h2>Schedule an appointment today!</h2>
    <h3>Klinke Piano Service</h3>
    <p className="mt-0">
      106 E Haddon Ave <br />
      Oaklyn NJ, 08107
      <br />
      <a href="tel:6092802849">609-280-2849</a>
      <br />
      <a href="mailto:klinkepianoservice@gmail.com">
        klinkepianoservice@gmail.com
      </a>
    </p>
    <div className="flex items-center mt-8">
      <div className="contact-cred flex items-center mr-8">
        <Mpt />
      </div>
      <div className="contact-cred flex items-center">
        <Pls />
      </div>
    </div>
  </Layout>
)

export default IndexPage
